<template lang="pug">
om-modal.custom-font-upload-modal(
  name="custom-font-upload"
  color="light"
  @beforeOpen="reset"
  width="650"
)
  template(slot="modal-header")
    .d-flex
      .brand-modal-title {{ $t('customFontUpload') }}
    .brand-modal-action-icon
      span.cursor-pointer(
        @click="$modal.hide('custom-font-upload')"
        title="close"
        aria-hidden="true"
      ) X
  template(slot="modal-body")
    template(v-if="!selectedFile")
      .font-size-0--875.mb-5.mt-3 {{ $t('customFontExtensions') }}
      upload-drop-zone(accept="application/zip" @change="processFile($event)")
    .position-relative.mb-3.mt-4(v-if="selectedFile")
      label(for="customFontName") {{ $t('name') }}
      .d-flex.position-relative
        input#customFontName.form-control.form-control-lg.brand-input.mr-4(
          type="text"
          :class="{ 'is-invalid': $v.fontName.$error }"
          :placeholder="$t('fontName')"
          v-model.trim="fontName"
        )
        .brand-input-error(v-if="$v.fontName.$error && !$v.fontName.required") {{ $t('requiredField') }}
        button.brand-btn.brand-btn-primary.mx-auto(@click="upload" :class="{ loading: loading }") {{ $t('upload') }}
    //- .brand-link.brand-link-secondary.text-center.mt-3(v-if="selectedFile" @click="reset") {{ $t('back') }}
    .text-center.font-size-0--875.mt-5 {{ $t('fontLicense') }}
  template.p-0(slot="modal-footer")
</template>

<script>
  import { restClient as axios } from '@/editor/axios';
  import { required } from 'vuelidate/lib/validators';
  import UploadDropZone from '@/components/UploadDropZone';

  export default {
    components: { UploadDropZone },

    data() {
      return {
        fontName: '',
        selectedFile: null,
        loading: false,
      };
    },

    validations: {
      fontName: {
        required,
      },
    },

    computed: {
      fileName() {
        return this.selectedFile ? this.selectedFile.name : '';
      },
    },
    methods: {
      reset() {
        this.$v.$reset();
        this.fontName = '';
        this.selectedFile = null;
        this.loading = false;
      },

      getExtensionFromStr(str) {
        return str.substring(str.lastIndexOf('.') + 1);
      },

      getFileNameFromPath(path) {
        return path.match(/[-_\w]+[.][\w]+$/i)[0];
      },

      async validateZipAndGetFileName(file) {
        const { default: JSZip } = await import('jszip');
        const zip = await JSZip.loadAsync(file);
        let name = '';

        const requiredExtensions = {
          eot: false,
          svg: false,
          ttf: false,
          woff: false,
          woff2: false,
        };

        zip.forEach((path, entry) => {
          const extension = this.getExtensionFromStr(entry.name);
          if (requiredExtensions.hasOwnProperty(extension)) {
            requiredExtensions[extension] = true;

            if (!name.length) {
              name = this.getFileNameFromPath(path).replace(`.${extension}`, '');
            }
          }
        });

        let hasEveryThing = true;
        const missingExtensions = [];

        for (const e of Object.keys(requiredExtensions)) {
          hasEveryThing = hasEveryThing && requiredExtensions[e];
          if (!requiredExtensions[e]) {
            missingExtensions.push(e);
          }
        }

        if (!hasEveryThing) {
          const error = new Error('Missing files');
          error.missingExtensions = missingExtensions;
          throw error;
        }

        return name;
      },

      async processFile(file) {
        const extension = this.getExtensionFromStr(file.name);
        if (extension !== 'zip') {
          this.$notify({
            type: 'error',
            title: this.$t('customFontErrors.extensionMismatch'),
          });
          return;
        }
        try {
          this.fontName = await this.validateZipAndGetFileName(file);
          this.selectedFile = file;
        } catch (e) {
          console.log({ e });
          this.reset();
          this.$notify({
            type: 'error',
            duration: 6000,
            title: this.$t('customFontErrors.missingFiles', {
              missingExtensions: e.missingExtensions.join(', '),
            }),
          });
        }
      },

      async upload() {
        this.$v.$touch();

        if (this.$v.$invalid || !this.selectedFile) {
          if (!this.selectedFile) {
            this.$notify({
              type: 'error',
              title: this.$t('customFontErrors.selectAFile'),
            });
          }
          return;
        }

        this.loading = true;

        const formData = new FormData();
        formData.append('name', this.fontName);
        formData.append('file', this.selectedFile);

        try {
          await axios.post('upload/custom-font', formData, {
            headers: {
              'content-type': 'multipart/form-data',
            },
          });

          this.$bus.$emit('getFonts');

          this.$modal.hide('custom-font-upload');
        } catch (e) {
          const errorKey = e.response.data.errorKey;
          this.$notify({
            type: 'error',
            title: this.$t(`customFontErrors.${errorKey}`),
          });
        }

        this.loading = false;
      },
    },
  };
</script>

<style lang="sass">
  .custom-font-upload-modal
    .brand-modal-title
      flex: none
    .brand-modal-action-icon
      top: 1.2rem !important
    .brand-modal-header
      padding-top: 0 !important
      padding-bottom: 0 !important
      border-bottom: 1px solid #e7e7e7
    .brand-modal-body
      padding-bottom: 1.875rem !important
    .brand-modal-footer
      margin: 0 !important
      padding: 0 !important
    .brand-input-error
      top: 0.225rem
      left: -10.5rem
</style>
