<template lang="pug">
.form-modal(v-if="showFormManager.deleteCustomStyle" @mousedown="cancel")
  .content(style="min-height: fit-content" @mousedown.stop="")
    .header.om-bottom-divider.p-3
      .header-inner
        .title
          .font-weight-bold.d-flex.align-items-center(style="font-size: 18px") {{ $t('customTheme.modal.deleteCustomStyle') }}
        .close
          om-button.ml-auto.p-1(
            icon="times"
            iconSize="1.715em"
            ghost
            iconOnly
            style="color: #b9bec6"
            @click="cancel()"
          )
    .body-content
      .d-flex.justify-content-center.align-items-center
        .row-holder.mb-2
          label(v-html="$t('customTheme.modal.deleteConfirm', { style: styleName })")
    .footer
      om-button.mr-3(ghost @click="cancel") {{ $t('cancel') }}
      om-button(primary @click="deleteStyle") {{ $t('delete') }}
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import DELETE_CUSTOM_THEME_STYLE from '@/graphql/DeleteCustomThemeStyle.gql';

  export default {
    data() {
      return {
        newStyleName: '',
      };
    },
    computed: {
      ...mapState(['showFormManager', 'formModalMetaData', 'selectedElement', 'themeKit']),
      styleName: {
        get() {
          return this.formModalMetaData.value;
        },
      },
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      cancel() {
        this.changeFormManagerVisibility({ hide: 'deleteCustomStyle' });
      },
      async deleteStyle() {
        try {
          const type = this.selectedElement?.type;
          const {
            data: {
              deleteCustomThemeStyle: { success },
            },
          } = await this.$apollo.mutate({
            mutation: DELETE_CUSTOM_THEME_STYLE,
            variables: {
              name: this.styleName,
              themeKit: this.themeKit.name,
              type,
            },
          });
          if (!success) throw new Error('unableToDelete');

          this.changeFormManagerVisibility({ hide: 'deleteCustomStyle' });
          this.$bus.$emit('custom-style-modified', { newName: null });
        } catch (error) {
          this.$OmNotification({
            color: 'danger',
            type: 'full',
            width: 248,
            ignoreDuplicates: true,
            text: this.$i18n.t(`customTheme.notifications.${error.message}`, {
              themeVersion: this.newStyleName,
            }),
          });
        }
      },
    },
  };
</script>
<style lang="sass" scoped>
  .text
    font-weight: 400
    font-size: 12px
    color: #505763
  .form-modal
    .content
      width: 26.25rem
      .header
        padding: unset !important
      .header-inner
        padding: 0
        display: flex
        width: 100%
        .title
          display: flex
          margin-left: 40px
          margin-top: 20px
          margin-bottom: 20px
        .close
          display: flex
          margin-left: auto
          margin-right: 25px
      .body-content
        margin-left: 40px
        margin-right: 40px
        margin-top: 24px
        .row-holder
          .each-radio
            display: flex
            min-width: 160px
            input[type=radio]
              align-self: center
              height: 20px
              width: 20%
            label
              font-size: 12px
              color: #505763
              align-self: center
      .footer
        min-height: 80px
        display: flex
        padding-right: 40px
  .row-holder
    width: 25.3rem
</style>
