<template lang="pug">
.form-modal(v-if="showFormManager.addNewStyle" @mousedown="cancel")
  .content(style="min-height: fit-content" @mousedown.stop="")
    .header.om-bottom-divider.p-3
      .header-inner
        .title
          .font-weight-bold.d-flex.align-items-center(style="font-size: 18px") {{ $t('customTheme.modal.title', { elementType: this.selectedElement.data.type }) }}
        .close
          om-button.ml-auto.p-1(
            icon="times"
            iconSize="1.715em"
            ghost
            iconOnly
            style="color: #b9bec6"
            @click="cancel()"
          )
    .body-content
      .d-flex.justify-content-center.align-items-center
        .row-holder.mb-2
          label.text.mb-0 {{ $t('customTheme.modal.inputName') }}
          om-input#newStyleName.w-100(
            v-model="newStyleName"
            ref="newStyleName"
            type="text"
            :placeholder="$t('customTheme.modal.inputPlaceholder')"
          )
    .footer
      om-button.mr-3(ghost @click="cancel") {{ $t('cancel') }}
      om-button(primary @click="save") {{ $t('save') }}
</template>
<script>
  import { mapState, mapMutations } from 'vuex';

  const frameStore = () => {
    const workspace = document.getElementById('workspaceFrame');
    if (!workspace) {
      return;
    }
    return workspace.contentWindow.om?.store;
  };

  export default {
    data() {
      return {
        newStyleName: '',
      };
    },
    computed: {
      ...mapState(['showFormManager', 'selectedElement']),
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility', 'setElementStylesChanged']),
      cancel() {
        this.newStyleName = '';
        this.changeFormManagerVisibility({ hide: 'addNewStyle' });
      },
      async save() {
        try {
          if (this.newStyleName.trim().length < 3) throw new Error('tooShort');

          const { themeOptions } = await frameStore().dispatch('customTheme/fetchThemeStyles');
          const alreadyExists = themeOptions.find(
            (themeOption) => themeOption.key === this.newStyleName,
          );

          if (alreadyExists) throw new Error('alreadyExists');

          const payload = {
            uid: this.selectedElement.uid,
            version: this.newStyleName,
          };
          this.$bus.$emit('setThemeStyle', payload);
          this.selectedElement.data.themeVersion = this.newStyleName;
          this.newStyleName = '';
          this.changeFormManagerVisibility({ hide: 'addNewStyle' });
          this.setElementStylesChanged(true);
        } catch (error) {
          this.$OmNotification({
            color: 'danger',
            type: 'full',
            width: 248,
            ignoreDuplicates: true,
            text: this.$i18n.t(`customTheme.notifications.${error.message}`, {
              themeVersion: this.newStyleName,
            }),
          });
        }
      },
    },
  };
</script>
<style lang="sass" scoped>
  .text
    font-weight: 400
    font-size: 12px
    color: #505763
  .form-modal
    .content
      width: 26.25rem
      .header
        padding: unset !important
      .header-inner
        padding: 0
        display: flex
        width: 100%
        .title
          display: flex
          margin-left: 40px
          margin-top: 20px
          margin-bottom: 20px
        .close
          display: flex
          margin-left: auto
          margin-right: 25px
      .body-content
        margin-left: 40px
        margin-right: 40px
        margin-top: 24px
        .row-holder
          .each-radio
            display: flex
            min-width: 160px
            input[type=radio]
              align-self: center
              height: 20px
              width: 20%
            label
              font-size: 12px
              color: #505763
              align-self: center
      .footer
        min-height: 80px
        display: flex
        padding-right: 40px
  .row-holder
    width: 25.3rem
</style>
