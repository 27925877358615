<template lang="pug">
.new-navigation(
  :class="{ 'new-navigation-dragging': pageDragging, 'new-navigation-hidden': isHidden, 'new-navigation-scrollable': isScrollable }"
)
  .new-navigation-tooltip(ref="pageTooltip" @mouseleave="hidePageTooltip()")
    template(v-if="hoveredPage === 'teaser'")
      .new-navigation-tooltip-item(v-if="isTeaserEnabled" @click.stop="disableTeaser")
        span.d-flex
          img.mr-3(:src="require('@/assets/editor/svg/TeaserHidden.svg')")
          | {{ $t('disableTeaser') }}
      .new-navigation-tooltip-item(v-else @click.stop="enableTeaser")
        span.d-flex
          img.mr-3(:src="require('@/assets/editor/svg/TeaserVisible.svg')")
          | {{ $t('showTeaser') }}
    template(v-else)
      .new-navigation-tooltip-item(
        v-if="hoveredPage && !pageHasReadOnlyElement(hoveredPage)"
        @click.stop="copyPage(hoveredPage.uid)"
      )
        span {{ $t('duplicate') }}
      .new-navigation-tooltip-item(v-if="pageCount !== 1" @click.stop="removePage(hoveredPage)")
        span {{ $t('delete') }}
      .new-navigation-tooltip-item(@click.stop="renamePage(hoveredPage)")
        span {{ $t('rename') }}

  .new-navigation-pane-toggle(@click="isHidden = !isHidden")
    fa-icon(:variant="isHidden ? 'fa-angle-up' : 'fa-angle-down'")
  .new-navigation-pane-wrap
    .new-navigation-pane
      .new-navigation-items-wrap
        .new-navigation-left-button(
          :class="{ 'new-navigation-left-button-hidden': isScrollableStart || !isScrollable }"
          @click="scrollPrev"
        )
        .new-navigation-right-button(
          :class="{ 'new-navigation-right-button-hidden': isScrollableEnd || !isScrollable }"
          @click="scrollNext"
        )
        .new-navigation-items(@scroll="onPagesScroll")
          .new-navigation-item.new-navigation-item-page(
            v-for="(page, pageIndex) in pages"
            :key="page.uid"
            :class="{ selected: isPageSelected(page), 'mobile-preview': mobilePreview }"
            :data-index="pageIndex"
            @click="onPageClick(page.uid)"
          )
            .new-navigation-item-thumb
              .new-navigation-item-iframe
                iframe
              .new-navigation-item-actions-button(
                @click.stop="() => {}"
                @click="showPageTooltip($event, page)"
              )
            .new-navigation-item-title(:title="page.data.title")
              span {{ page.data.title }}
              .new-navigation-item-actions-button.new-navigation-item-actions-button-floating(
                v-if="isHidden"
                @click="showPageTooltip($event, page)"
              )
      .new-navigation-item.new-navigation-item-teaser(
        v-if="!isEmbedded"
        @click="onTeaserClick"
        :class="{ selected: isTeaserSelected, 'mobile-preview': mobilePreview }"
      )
        .new-navigation-item-thumb
          .new-navigation-item-iframe
            iframe
          .new-navigation-item-teaser-hidden(v-if="!isHidden && !isTeaserEnabled")
            img(:src="require('@/assets/editor/svg/TeaserHidden.svg')")
          .new-navigation-item-actions-button(
            @click.stop="() => {}"
            @click="showPageTooltip($event, 'teaser')"
          )
        .new-navigation-item-title
          .new-navigation-item-title-teaser(:style="{ color: !isTeaserEnabled ? '#B9BEC6' : '' }")
            | {{ $t('tab') }}
            img.ml-2(
              v-if="isHidden && !isTeaserEnabled"
              :src="require('@/assets/editor/svg/TeaserHidden.svg')"
            )
          .new-navigation-item-actions-button.new-navigation-item-actions-button-floating(
            v-if="isHidden"
            @click="showPageTooltip($event, 'teaser')"
          )
      .new-navigation-item.new-navigation-item-add(@click="addPage()" :title="$t('addNewPage')")
        .new-navigation-item-thumb
          i.fas.fa-plus-circle.mr-2
          div {{ $t('addNewPage') }}
</template>

<script>
  import Sortable from 'sortablejs';
  import TeaserSvg from '@/editor/components/svg/Teaser.vue';
  import TeaserSvgV2 from '@/editor/components/svg/TeaserV2.vue';
  import PageStep from '@/editor/components/svg/PageStep.vue';
  import readOnlyElements from '@/editor/config/readOnlyElements';
  import { createNamespacedHelpers, mapGetters, mapMutations, mapState } from 'vuex';
  import { TEMPLATE_FEATURES } from '@/editor/util';
  import { frameStore } from '@/util';
  import runtimeConfig from '@/config/runtime';

  const { mapState: mapProductTourState, mapActions: mapProductTourActions } =
    createNamespacedHelpers('productTour');

  const THEME_SECTION_KEY = 'theme';
  const THEME_SECTION_INDEX = 0;
  const DISPLAY_SECTION_KEY = 'display';
  const DISPLAY_SECTION_INDEX = 1;
  const SECTIONS_MAP = {
    [THEME_SECTION_KEY]: { index: THEME_SECTION_INDEX },
    [DISPLAY_SECTION_KEY]: { index: DISPLAY_SECTION_INDEX },
  };

  export default {
    components: { TeaserSvg, TeaserSvgV2, PageStep },
    props: {
      productTourVisible: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      editorContent: '',
      hoveredPage: null,
      pageDragging: false,
      loadTemplate: true,
      teaserSelected: false,
      isHidden: window.innerHeight < 800,
      isScrollable: false,
      isScrollableStart: true,
      isScrollableEnd: false,
      teaserLoading: false,
      THEME_SECTION_KEY,
      DISPLAY_SECTION_KEY,
      SECTIONS_MAP,
    }),
    computed: {
      ...mapState([
        'pages',
        'selectedPage',
        'selectedElement',
        'teaserPreview',
        'mobilePreview',
        'colorPicker',
        'isActiveUndo',
        'account',
        'mockSiteLayout',
        'globalStyle',
      ]),
      ...mapGetters([
        'pageCount',
        'isEmbedded',
        'teaserPage',
        'hasTemplateFeature',
        'template',
        'isUniversal',
      ]),
      ...mapProductTourState(['driver']),
      isTeaserSelected() {
        return this.teaserPreview || (this.selectedPage && this.selectedPage.isTeaser);
      },
      isTeaserEnabled() {
        if (this.loadTemplate) return true;
        let before;
        let after;
        if (this.isNewTeaser) {
          if (!this.teaserPage) return true; // if editor is not loaded yet, we don't want to show the icon
          ({ before, after } = this.teaserPage.data.display);
        } else {
          if (!this.globalStyle.tab) return true; // same
          ({ before, after } = this.globalStyle.tab.display);
        }

        return before || after;
      },
      isNewTeaser() {
        return this.hasTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER);
      },
    },
    watch: {
      isHidden(val) {
        this.$bus.$emit('editorPagesPaneToggle', !val);
        if (!val) {
          this.renderAllPreview();
        }
      },
      isTeaserSelected() {
        this.renderAllPreview();
      },
      mockSiteLayout() {
        this.renderAllPreview();
      },
      mobilePreview(newValue) {
        if (newValue) {
          this.isHidden = true;
        }
      },
      productTourVisible(visible) {
        if (visible) this.isHidden = false;
      },
    },
    created() {
      this.loadTemplate = true;
      this.$bus.$on('endLoadTemplate', this.setLoadTemplate);
      this.$bus.$on('toggleTeaser', () => {
        if (this.teaserPreview) {
          this.hideOldTeaser();
        } else {
          this.showOldTeaser();
        }
      });
      this.$bus.$on('togglePagesPane', (value) => {
        this.isHidden = !value;
      });
    },
    mounted() {
      const container = this.$el.querySelector('.new-navigation-items');
      window.addEventListener('resize', this.checkScrollable);
      document.addEventListener('click', this.onDocumentClick);

      [
        'loadTemplate',
        'teaserSelected',
        'pages',
        'selectedPage',
        'teaserPreview',
        'mobilePreview',
      ].forEach((prop) => {
        this.$watch(prop, () => {
          this.checkScrollable();
        });
      });

      ['loadTemplate', 'mobilePreview'].forEach((prop) => {
        this.$watch(prop, () => {
          this.renderAllPreview();
        });
      });

      ['selectedPage'].forEach((prop) => {
        this.$watch(prop, (value, oldValue) => {
          if (oldValue) {
            this.renderPreviewForPage(oldValue.uid);
          }
        });
      });

      this.$watch('colorPicker.show', (isVisible) => {
        if (
          !isVisible &&
          this.colorPicker.property &&
          this.colorPicker.property.includes('globalStyle')
        ) {
          this.renderPreviews();
        }
      });

      Sortable.create(container, {
        animation: 150,
        draggable: '.new-navigation-item',
        filter: '.not-sortable',
        preventOnFilter: true,
        chosenClass: 'dragging',
        onStart: () => {
          this.pageDragging = true;
          if (this.isPageTooltipVisible) {
            this.hidePageTooltip();
          }
        },
        onEnd: (e) => {
          this.pageDragging = false;
          const { oldIndex, newIndex } = e;
          if (oldIndex !== newIndex) {
            this.movePage(oldIndex, newIndex);
          }
        },
      });

      this.$bus.$on('re-render-all-previews', this.renderAllPreview);
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.checkScrollable);
      document.removeEventListener('click', this.onDocumentClick);
    },
    methods: {
      ...mapMutations([
        'selectPage',
        'setStateAttr',
        'activatePaneL2',
        'deselectAll',
        'setTeaserVisibility',
      ]),
      ...mapProductTourActions(['next']),
      onDocumentClick(e) {
        if (!this.isPageTooltipVisible) return;
        const targetEl = e.target;
        if (!targetEl.classList.contains('new-navigation-item-actions-button')) {
          this.hidePageTooltip();
        }
      },
      onPagesScroll() {
        this.hidePageTooltip();
        this.checkScrollable();
      },
      checkScrollable() {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.new-navigation-items');
          if (!el) return;
          this.isScrollable = el.scrollWidth > el.offsetWidth;
          this.isScrollableStart = el.scrollLeft === 0;
          this.isScrollableEnd = el.scrollLeft === el.scrollWidth - el.offsetWidth;
        });
      },
      scrollPrev() {
        const el = this.$el.querySelector('.new-navigation-items');
        if (!el) return;
        el.scrollTo({ left: el.scrollLeft - 144, behavior: 'smooth' });
      },
      scrollNext() {
        const el = this.$el.querySelector('.new-navigation-items');
        if (!el) return;
        el.scrollTo({ left: el.scrollLeft + 144, behavior: 'smooth' });
      },
      scrollPagesToEnd() {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.new-navigation-items');
          if (!el) return;
          el.scrollTo({ left: el.scrollWidth, behavior: 'smooth' });
        });
      },

      setLoadTemplate(value) {
        this.loadTemplate = !value;
        setTimeout(() => {
          this.renderAllPreview();
        }, 250);

        if (value) {
          this.$bus.$emit('editorPagesPaneToggle', !this.isHidden);
        }
      },
      isPageSelected(page) {
        return this.selectedPage && this.selectedPage.uid === page.uid && !this.teaserPreview;
      },
      showPageTooltip(event, page) {
        this.hoveredPage = page;
        if (event && event.type === 'mouseenter' && !this.isHidden) return;
        if (this.isPageTooltipVisible && page === this.hoveredPage) {
          this.hidePageTooltip();
          return;
        }

        this.$nextTick(() => {
          const tooltipEl = this.$refs.pageTooltip;
          const containerEl = this.$el;
          const targetEl = event.target;

          const { x: containerX, bottom: containerBottom } = containerEl.getBoundingClientRect();
          const {
            x: targetElX,
            bottom: targetBottom,
            height: targetHeight,
          } = targetEl.getBoundingClientRect();

          const x = targetElX - containerX;

          tooltipEl.style.left = `${x}px`;
          tooltipEl.style.bottom = `${containerBottom - targetBottom + targetHeight}px`;
          tooltipEl.style.opacity = 1;
          tooltipEl.style.pointerEvents = 'auto';
          this.isPageTooltipVisible = true;
        });
      },
      hidePageTooltip(event) {
        if (event && event.type === 'mouseleave') {
          if (!this.isHidden) return;
          if (
            this.isHidden &&
            event.relatedTarget &&
            event.relatedTarget === this.$refs.pageTooltip
          ) {
            return;
          }
        }

        this.isPageTooltipVisible = false;
        this.$refs.pageTooltip.style.opacity = 0;
        this.$refs.pageTooltip.style.left = null;
        this.$refs.pageTooltip.style.bottom = null;
        this.$refs.pageTooltip.style.pointerEvents = null;
      },
      pageHasReadOnlyElement(page) {
        let value = false;

        window.om.template.elements.forEach((el) => {
          if (el.pageId === page.uid && readOnlyElements.includes(el.type)) {
            value = true;
          }
        });

        return value;
      },
      onPageClick(pageId) {
        if (
          pageId !== this.selectedPage.uid &&
          this.driver &&
          this.driver.hasHighlightedElement() &&
          this.driver.getHighlightedElement().node ===
            document.querySelector('.new-navigation-items')
        ) {
          this.next();
        }

        this.selectPage(pageId);
        this.$nextTick(() => {
          if (!this.selectedPage.isTeaser) {
            this.deselectAll();
            this.$bus.$emit('pageNavigationClick');
          }
        });

        if (this.teaserPreview) {
          this.setStateAttr({ attr: 'teaserPreview', value: false });
          this.$bus.$emit('setTeaserPreview', false);
        }
      },
      copyPage(uid) {
        this.$bus.$emit('copyPage', uid);
        this.scrollPagesToEnd();
        this.hidePageTooltip();
        this.renderAllPreview();
      },
      addPage() {
        this.$bus.$emit('addPage');
        this.scrollPagesToEnd();
      },
      movePage(from, to) {
        this.$bus.$emit('movePage', { from, to });
      },
      removePage(page) {
        // eslint-disable-next-line no-alert
        const confirm = window.confirm('Delete this page?');
        if (confirm) {
          this.$store.commit('removePage', page);
          this.$bus.$emit('removePage', page);
        }
        this.hidePageTooltip();
      },
      renamePage(page) {
        this.$modal.show('page-rename', { page });
        this.hidePageTooltip();
      },
      getTeaser() {
        return frameStore().getters.elements.find((e) => e.isTeaser);
      },
      getSectionKey(section) {
        return this.isUniversal && section === THEME_SECTION_KEY ? section : DISPLAY_SECTION_KEY;
      },
      openSection(section) {
        const sectionKey = this.getSectionKey(section);
        window.parent.om.bus.$emit('show-left-sidebar-content', {
          buttonIndex: this.SECTIONS_MAP[sectionKey].index,
          withoutCloseCase: true,
        });
      },
      onTeaserClick() {
        this.openSection();
        if (this.isNewTeaser) {
          const teaser = this.getTeaser();
          if (teaser) {
            this.onPageClick(teaser.uid);
            this.$nextTick(() => this.activatePaneL2('TeaserPane'));
          }
        } else {
          this.showOldTeaser();
        }

        if (
          this.driver &&
          this.driver.hasHighlightedElement() &&
          this.driver.getHighlightedElement().node ===
            document.querySelector('.new-navigation-item-teaser')
        ) {
          setTimeout(() => {
            this.$bus.$emit('setFakeEl', '[class*="om-tab-wrapper"]');
            if (this.isNewTeaser) {
              this.$bus.$emit('selectElement', this.selectedPage.uid);
            }
            this.$bus.$emit('setOverlayClickable', false);
            setTimeout(() => {
              this.$bus.$emit('setFakeTeaserSettings');
            }, 500);
          }, 500);
        }
      },
      showOldTeaser() {
        this.$bus.$emit('resetEditorPane');
        this.setStateAttr({ attr: 'teaserPreview', value: true });
        this.$bus.$emit('setTeaserPreview', true);
        this.activatePaneL2('TeaserPane');
      },
      hideOldTeaser() {
        this.$bus.$emit('resetEditorPane');
        this.setStateAttr({ attr: 'teaserPreview', value: false });
        this.$bus.$emit('setTeaserPreview', false);
        this.activatePaneL2('TeaserPane');
      },
      async getEditorContent() {
        const { data } = await this.$axios.post(runtimeConfig.VUE_APP_SSR_URL, {
          template: frameStore().state.template,
          campaignId: this.$route.params.campaignId,
          variantId: this.$route.params.id,
          databaseId: this.$route.params.userId,
        });
        this.editorContent = data;
      },
      async createPreview(pageIndex, iframeEl) {
        const self = this;
        const editorContent = this.editorContent;
        const newIframe = document.createElement('iframe');
        newIframe.style.display = 'none';
        iframeEl.parentNode.appendChild(newIframe);
        setTimeout(() => {
          iframeEl?.parentNode?.querySelectorAll('iframe').forEach((oldIframe) => {
            if (oldIframe !== newIframe) oldIframe.remove();
          });
          newIframe.style.display = 'block';
        }, 800);
        const d = newIframe.contentWindow.document;
        d.open('text/html', 'replace');
        d.write(editorContent);
        d.close();
        const { databaseId } = this.account;
        function fixDOM() {
          d.documentElement.style.height = '100%';
          d.getElementsByTagName('body')[0].style.height = '100%';
          const overlayEl = d.querySelector('.om-overlay');
          const tabWrapperEl = d.querySelector('.om-tab-wrapper');
          const tabWrapper2El = d.querySelector('.om-tab-wrapper-v2');
          if (pageIndex === 'teaser') {
            if (overlayEl) overlayEl.style.display = 'none';
            if (tabWrapperEl) tabWrapperEl.style.display = '';
            if (tabWrapper2El) tabWrapper2El.style.display = '';
            d.documentElement.style.backgroundColor = '#f2f2f2';
            d.body.style.backgroundColor = '#f2f2f2';
          } else {
            if (overlayEl) overlayEl.style.display = '';
            if (tabWrapperEl) tabWrapperEl.style.display = 'none';
            if (tabWrapper2El) tabWrapper2El.style.display = 'none';
          }
          d.querySelectorAll('.om-animated, .om-selected').forEach((el) => {
            el.classList.remove('om-animated');
            el.classList.remove('om-selected');
          });
          d.querySelectorAll('.om-overlay .om-canvas').forEach((pageEl, index) => {
            if (index !== pageIndex) pageEl.classList.add('om-canvas-hidden');
            else pageEl.classList.remove('om-canvas-hidden');
          });

          if (self.isEmbedded) {
            self.createMockLayout(d);
          }

          newIframe.contentWindow.loadFonts(databaseId);
        }

        return new Promise((resolve) => {
          const contentLoadedListener = () => {
            fixDOM();
            d.removeEventListener('DOMContentLoaded', contentLoadedListener);
            resolve();
          };

          try {
            fixDOM();
            resolve();
          } catch (err) {
            d.addEventListener('DOMContentLoaded', contentLoadedListener);
          }
        });
      },
      async createPagePreviews() {
        this.pages.forEach((page, pageIndex) => this.createPagePreview(pageIndex));
      },
      async createPagePreview(pageIndex) {
        const iframeEl = this.$el.querySelector(
          `.new-navigation-item-page[data-index="${pageIndex}"] iframe`,
        );
        if (!iframeEl) return;
        await this.createPreview(pageIndex, iframeEl);
      },
      async createTeaserPreview() {
        const teaserIframeEl = this.$el.querySelector(`.new-navigation-item-teaser iframe`);
        if (teaserIframeEl) {
          await this.createPreview('teaser', teaserIframeEl);
        }
      },
      renderAllPreview() {
        if (this.isHidden) return;
        clearTimeout(this.renderAllPreviewsTimeOut);
        this.renderAllPreviewsTimeOut = setTimeout(async () => {
          await this.getEditorContent();
          this.createPagePreviews();
          this.createTeaserPreview();
        }, 200);
      },
      renderPreviews() {
        if (this.isHidden) return;
        clearTimeout(this.renderPreviewsTimeOut);
        this.renderPreviewsTimeOut = setTimeout(async () => {
          await this.getEditorContent();
          this.createPagePreviews();
        }, 200);
      },
      renderPreviewForPage(pageId) {
        if (this.isHidden) return;
        clearTimeout(this.renderPreviewForPageTimeOut);
        this.renderPreviewForPageTimeOut = setTimeout(async () => {
          await this.getEditorContent();
          const pageIndex = this.pages.findIndex((page) => page.uid === pageId);
          this.createPagePreview(pageIndex);
        }, 200);
      },
      renderTeaser() {
        if (this.isHidden) return;
        clearTimeout(this.renderTeaserTimeout);
        this.renderTeaserTimeout = setTimeout(async () => {
          await this.getEditorContent();
          this.createTeaserPreview();
        }, 200);
      },
      createMockLayout(d) {
        const mockBody = d.createElement('div');
        mockBody.className = `om-mock-body ${this.mobilePreview ? 'mobile' : ''}`;

        const mockHeader = d.createElement('div');
        mockHeader.className = 'om-mock-header';

        const mockMain = d.createElement('div');
        mockMain.className = `om-mock-main ${this.mockSiteLayout}`;

        d.body.appendChild(mockBody);
        mockBody.appendChild(mockHeader);
        mockBody.appendChild(mockMain);

        const mockColConfigs = {
          desktop: [
            { className: 'om-mock-col om-mock-col-side' },
            { className: 'om-mock-col om-mock-col-center', nestedDiv: true },
            { className: 'om-mock-col om-mock-col-side' },
          ],
          mobile: [{ className: 'om-mock-col om-mock-col-center', nestedDiv: true }],
        };

        const deviceBaseMockColConfigs = this.mobilePreview
          ? mockColConfigs.mobile
          : mockColConfigs.desktop;

        for (const config of deviceBaseMockColConfigs) {
          const mockCol = d.createElement('div');
          mockCol.className = config.className;
          mockMain.appendChild(mockCol);
          if (config.nestedDiv) {
            const fragment = d.createDocumentFragment();
            fragment.appendChild(d.querySelector('.om-holder'));
            mockCol.appendChild(fragment);
          }
        }

        const mockFooter = d.createElement('div');
        mockFooter.className = 'om-mock-footer';
        mockBody.appendChild(mockFooter);

        const deviceBasedFooterCnt = this.mobilePreview ? 1 : 3;
        for (let i = 0; i < deviceBasedFooterCnt; i++) {
          const mockFooterCol = d.createElement('div');
          mockFooterCol.className = 'om-mock-footer-col';
          mockFooter.appendChild(mockFooterCol);
          if (i === 1) {
            mockFooterCol.classList.add('om-mock-footer-col-center');
          }
        }

        const mockSiteCss = [
          ...document.querySelector('#workspaceFrame').contentDocument.styleSheets,
        ]
          .map((styleSheet) => {
            try {
              return [...styleSheet.cssRules].map((rule) => rule.cssText).join('');
            } catch (e) {
              return null;
            }
          })
          .filter(Boolean)
          .join('\n');

        const workspaceCSSLink = this.getWorkspaceCSSLink();
        const styleElement = document.createElement('style');
        if (styleElement.styleSheet) {
          styleElement.styleSheet.cssText = mockSiteCss;
        } else {
          styleElement.appendChild(document.createTextNode(mockSiteCss));
        }
        d.getElementsByTagName('head')[0].appendChild(styleElement);

        if (workspaceCSSLink) {
          d.getElementsByTagName('head')[0].appendChild(workspaceCSSLink);
        }

        d.querySelector('.om-holder').style.position = 'unset';
        d.querySelector('.om-workspace-content').style.height = '100%';
      },
      getWorkspaceCSSLink() {
        const workspaceFrame = document.querySelector('#workspaceFrame');
        const workspaceCSSLink = workspaceFrame.contentWindow.document.querySelector(
          'link[href*="css/workspace"]',
        );

        if (!workspaceCSSLink) return;

        const newLink = document.createElement('link');
        newLink.href = workspaceCSSLink.href;
        newLink.rel = workspaceCSSLink.rel;
        return newLink;
      },
      enableTeaser() {
        if (this.isNewTeaser) {
          this.$bus.$emit('setTeaserVisibility', true);
        } else {
          this.setTeaserVisibility(true);
        }
        this.hidePageTooltip();
      },
      disableTeaser() {
        if (this.isNewTeaser) {
          this.$bus.$emit('setTeaserVisibility', false);
        } else {
          this.setTeaserVisibility(false);
        }
        this.hidePageTooltip();
      },
    },
  };
</script>
